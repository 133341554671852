import React, { useEffect, useState, CSSProperties, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CancelIcon from '@mui/icons-material/Cancel';
import type { UploadProps, ProgressProps } from 'antd';
import { message, Upload as Uploaded, Progress } from 'antd';
import { getSessionStorageData, setSessionStorageData } from "@src/constants/screen/util";
import { listUploadImg } from "@src/constants/screen/util";
import "./upload.css"

export default function Upload() {
    interface image {
        imageUpload: File,
        imagePreview: string,
        progress: number,
        status: statusUpload
    }
    type statusUpload = "uploading" | "failed" | "success"
    const { Dragger } = Uploaded;
    const { id, event } = useParams()
    const [imageUpload, setImageUpload] = useState<Array<image>>([])
    const [eleImageUpload, setEleImageUpload] = useState<Array<any>>([])
    const [isDisableBtn, setIsDisableBtn] = useState<boolean>(true)
    const [processUpload, setProcessUpload] = useState<boolean>(false)
    const { t } = useTranslation()
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    let curUpload: Array<image> = []
    let beforeUploadList: Array<image> = []

    const twoColors: ProgressProps['strokeColor'] = {
        '0%': '#108ee9',
        '100%': '#87d068',
    };

    useEffect(() => {
        if (processUpload) {
            let imageUploaded = [...imageUpload]
            let objData = {
                uploadImage: imageUploaded
            }

            setTimeout(() => {
                imageUploaded.forEach(item => {
                    if (item.status === "uploading") {
                        item.progress = 70;
                    }
                });
                objData.uploadImage = imageUploaded
                setImageUpload(imageUploaded)
                setTimeout(() => {
                    imageUploaded.forEach(item => {
                        if (item.status === "uploading") {
                            item.progress = 100
                            item.status = "success"
                        }
                    });
                    objData.uploadImage = imageUploaded

                    let tmpCreateData = getSessionStorageData("tmpCreateData")
                    tmpCreateData = Object.assign(tmpCreateData, objData)
                    tmpCreateData.status = event
                    tmpCreateData.paging = 6
                    setSessionStorageData("tmpCreateData", tmpCreateData)
                    setProcessUpload(false)
                    setImageUpload([...imageUploaded])
                }, 1000)
                setIsDisableBtn(false)
            }, 20)
        }
    }, [processUpload])


    const removeSelectedImage = (image: string) => {
        let listImg = imageUpload.filter((e) => e.imagePreview !== image)
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        let objData = {
            uploadImage: listImg
        }
        let tmpCreateDataStr = sessionStorage.getItem("tmpCreateData")
        let tmpCreateData = tmpCreateDataStr ? JSON.parse(tmpCreateDataStr!) : {}
        tmpCreateData = Object.assign(tmpCreateData, objData)
        sessionStorage.setItem("tmpCreateData", JSON.stringify(tmpCreateData))
        setImageUpload(listImg);
        if (listImg.length === 0) {
            setIsDisableBtn(true)
        }
    };

    const props: UploadProps = {
        name: "file",
        multiple: true,
        onChange(info) {
            const { status } = info.file;
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
            if (curUpload.length) {
                curUpload.map((item) => {
                    beforeUploadList.push(item)
                })
                setImageUpload(beforeUploadList)
                curUpload = []
                setProcessUpload(true)
            }
        },
        beforeUpload(e) {
            if (listUploadImg.includes(e.type)) {
                curUpload.push({
                    imageUpload: e,
                    imagePreview: URL.createObjectURL(e),
                    progress: 20,
                    status: "uploading"
                })
            }
            beforeUploadList = [...imageUpload]
            return false
        }
    };

    useEffect(() => {
        let tmpCreateDataStr = sessionStorage.getItem("tmpCreateData")
        let tmpCreateData = tmpCreateDataStr ? JSON.parse(tmpCreateDataStr!) : {}
        if (tmpCreateData?.uploadImage) {
            setImageUpload(tmpCreateData.uploadImage)
            tmpCreateData.status = event
            tmpCreateData.paging = 6
            sessionStorage.setItem("tmpCreateData", JSON.stringify(tmpCreateData))
            setIsDisableBtn(false)
        }
    }, [])

    const styles: { [key: string]: CSSProperties } = {
        delete: {
            position: 'absolute',
            top: 15,
            right: 0,
            cursor: 'pointer',
            padding: 5,
            background: '#f14668',
            color: 'white',
            border: 'none',
            borderRadius: '30px',
            fontSize: "30px"
        },
    };

    useEffect(() => {
        let elementsUpload: Array<any> = []
        let columnsElement: Array<any> = []
        let flagHasColumns: boolean = false
        imageUpload.map((item, index) => {
            if ((index + 1) % 3 !== 0) {
                flagHasColumns = false
                columnsElement.push(
                    <div className="column is-4" key={index}>
                        <div style={{ position: "relative", width: "150px", height: "200px" }} >
                            <img className="upload-img-pre" alt="not found" src={item?.imagePreview} />
                            <CancelIcon className="delete-img" onClick={() => removeSelectedImage(item.imagePreview)} />
                            {item.status === "uploading" ? <Progress percent={item.progress} strokeColor={twoColors} size={{ height: 8 }} format={() => ''} /> : ""}
                        </div>
                    </div>
                )
            } else {
                flagHasColumns = true
                elementsUpload.push(
                    <div className="columns" style={{ marginBottom: "-50px" }}>
                        {
                            columnsElement.map((itemEle) => {
                                return itemEle
                            })
                        }
                        <div className="column is-4" key={index}>
                            <div style={{ position: "relative", width: "150px", height: "200px" }} >
                                <img className="upload-img-pre" alt="not found" src={item?.imagePreview} />
                                <CancelIcon className="delete-img" onClick={() => removeSelectedImage(item.imagePreview)} />
                                {item.status === "uploading" ? <Progress percent={item.progress} strokeColor={twoColors} size={{ height: 8 }} format={() => ''} /> : ""}
                            </div>
                        </div>
                    </div>
                )
                columnsElement = []
            }
        })
        if (!flagHasColumns) {
            elementsUpload.push(
                <div className="columns">
                    {
                        columnsElement.map((itemEle) => {
                            return itemEle
                        })
                    }
                </div>
            )
        }
        setEleImageUpload(elementsUpload)
    }, [imageUpload])

    return (
        <div className="box" style={{ marginBlockStart: "80px", borderRadius: "90px" }} >
            <div className="columns is-moblie">
                <div className="column is-5 is-offset-1" style={{ marginBlockStart: "20px" }}>
                    <i className="fas fa-image" style={{ fontSize: "50px" }}></i>
                    <br />
                    <h1 className="content-header font-bold">{t("UPLOAD_PAGE.HEADER")}</h1>
                    <p className=""><span className="has-text-danger">*</span>&nbsp;{t("UPLOAD_PAGE.TITLE")}</p><br />
                    <div className="column">
                        <Dragger {...props}
                            accept={listUploadImg}
                            showUploadList={false}
                            style={{ borderRadius: "25px" }}
                        >
                            <br /><br /><br /><br /><br />
                            <p className="ant-upload-drag-icon">
                                <i className="fas fa-upload" style={{ fontSize: "50px" }}></i>
                            </p>
                            <span className="file-label content-center" style={{ height: "180px", fontSize: "18px" }}>{t("UPLOAD_PAGE.FILE_TYPE")}</span>
                        </Dragger>
                    </div>
                    {/* <label className="file-label">
                            <input className="file-input" type="file" name="uploadImage" accept="image/*" onChange={handleFileUpload} ref={fileInputRef} />
                            <span className="file-cta" style={{ borderRadius: "25px" }}>
                                <br /><br /><br /><br /><br />
                                <span className="file-icon" >
                                    <i className="fas fa-upload" style={{ fontSize: "50px" }}></i>
                                </span><br />
                                <span className="file-label" style={{ height: "180px", fontSize: "18px" }}>{t("UPLOAD_PAGE.FILE_TYPE")}</span>
                            </span>
                        </label> */}

                    <br /><br />
                </div>
                <div className="column is-5" style={{ marginBlockStart: "20px" }}>
                    <h1 className="content-sub-header content-left">{t("UPLOAD_PAGE.FILE_UPLOAD")}</h1>
                    <div className={eleImageUpload.length > 2 ? "detail-scroll-upload" : "detail-upload"}>
                        {eleImageUpload}
                    </div>
                    <Link to="/register/post/6"><button className="button is-large is-fullwidth App" style={{ backgroundColor: "#C8F026", fontSize: "18px", height: "70px" }} disabled={isDisableBtn}>{t("BUTTONS.NEXT")}</button></Link>
                </div>
            </div>
        </div>
    )
}