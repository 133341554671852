import React, { RefCallback, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

interface propsContactUs {
    open: boolean;
    handleClose: RefCallback<any>;
}


export default function ContactUs(props: propsContactUs) {
    const { t } = useTranslation();
    const { open, handleClose } = props

    function handleCancel(e: MouseEvent) {
        handleClose("contactUs")
    }

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            fullWidth
            maxWidth="md"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            PaperProps={{
                style: {
                    borderRadius: "15px", // Adjust the value as needed
                }
            }}
        >
            <DialogTitle id="scroll-dialog-title">
                <div className="columns">
                    <div className="column is-6">
                        <h3 className="content-sub-header content-left font-bold">{t("SETTING_PAGE.CONTACTUS")}</h3>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent dividers={false} style={{ height: "550px", overflow: "hidden" }}>
                {/* <div className="box" style={{ borderRadius: "30px" }}> */}
                <div className="detail-dialog">
                    <div className="columns">
                        <div className="column">
                            <h3 className="content-sub-header font-bold" style={{ marginBottom: "15px" }}>THAILAND {<span style={{ color: "green" }}>GREENHOUSE</span>} GAS MANAGEMENT ORGANIZATION</h3>
                            <p className="content-left" style={{ marginBottom: "15px" }}>{t("SETTING_PAGE.CONTACTUS_DIAG.ADDRESS1")}</p>
                            <p className="content-left" >{t("SETTING_PAGE.CONTACTUS_DIAG.TEL_TITLE")}<span style={{ color: "#EA5555" }}>{t("SETTING_PAGE.CONTACTUS_DIAG.TEL1")}</span></p>
                            <p className="content-left" >{t("SETTING_PAGE.CONTACTUS_DIAG.EMAIL_TITLE")}<span style={{ color: "#55ACEA" }}>{t("SETTING_PAGE.CONTACTUS_DIAG.EMAIL1")}</span></p>
                            <p className="content-left" style={{ marginBottom: "15px" }}>{t("SETTING_PAGE.CONTACTUS_DIAG.WEBSITE_TITLE")}<span style={{ color: "#55ACEA", textDecoration: "underline" }}>{t("SETTING_PAGE.CONTACTUS_DIAG.WEBSITE1")}</span></p>
                            <h3 className="content-sub-header font-bold">{t("SETTING_PAGE.CONTACTUS_DIAG.HEADER2")}</h3>
                            <p className="content-left" >{t("SETTING_PAGE.CONTACTUS_DIAG.CONTENT2")}</p>
                            <p className="content-left" style={{ marginBottom: "15px" }}>{t("SETTING_PAGE.CONTACTUS_DIAG.ADDRESS2")}</p>
                            <p className="content-left" >{t("SETTING_PAGE.CONTACTUS_DIAG.TEL_TITLE")}<span style={{ color: "#EA5555" }}>{t("SETTING_PAGE.CONTACTUS_DIAG.TEL1")}</span></p>
                            <p className="content-left" >{t("SETTING_PAGE.CONTACTUS_DIAG.EMAIL_TITLE")}<span style={{ color: "#55ACEA" }}>{t("SETTING_PAGE.CONTACTUS_DIAG.EMAIL1")}</span></p>
                            <p className="content-left" style={{ marginBottom: "15px" }}>{t("SETTING_PAGE.CONTACTUS_DIAG.WEBSITE_TITLE")}<span style={{ color: "#55ACEA", textDecoration: "underline" }}>{t("SETTING_PAGE.CONTACTUS_DIAG.WEBSITE1")}</span></p>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </DialogContent>
            <DialogActions>
                <div className="column">
                    <div className="buttons content-center">
                        <button className="button is-rounded is-link App" type="button" onClick={handleCancel}>{t("BUTTONS.BACKWARD")}</button>
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    );
}
