import React, { useEffect, useState, MouseEvent } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CloudUploadOutlined } from '@ant-design/icons';
import type { UploadProps, ProgressProps } from 'antd';
import { message, Upload, Progress } from 'antd';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    CarbonCategory,
} from "../../constants/screen/detailEvent";
import { eventType, eventTypeIcon } from "../../constants/screen/eventType";
import { bytesToMegabytes, convertToBlobPart, getSessionStorageData, setSessionStorageData } from "../../constants/screen/util"
import pdfPreview from "../../constants/img/png-preview-pdf.png"
import "./attachment.css"

type statusUpload = "uploading" | "failed" | "success"
interface objDetailUploaded {
    uid: string;
    name: string;
    file: any;
    size: string;
    preview: string;
    status: statusUpload;
    progress: number;
}
interface objUpload {
    [key: string]: objDetailUploaded[];
}

export default function Attachment() {
    const { id, event } = useParams()
    const { t } = useTranslation()
    const { Dragger } = Upload;
    const [eleImageUpload, setEleImageUpload] = useState<objUpload>()
    const [eventUploadCss, setEventUploadCss] = useState<any>({})
    const [eventUpload, setEventUpload] = useState<string>("")
    const [fileList, setFileList] = useState<Array<objDetailUploaded>>([])
    const [processUpload, setProcessUpload] = useState<boolean>(false)
    const [isDisableBtn, setIsDisableBtn] = useState<boolean>(true)
    let curUpload: Array<objDetailUploaded> = []
    let beforeUploadList: Array<objDetailUploaded> = []

    //Coding for onChange upload here =====
    const props: UploadProps = {
        name: "file",
        multiple: true,
        onChange(info) {
            const { status } = info.file;
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }

            curUpload.map((item) => {
                beforeUploadList.push(item)
            })
            setFileList(beforeUploadList)
            curUpload = []
            setProcessUpload(true)
        },
        beforeUpload(e) {
            curUpload.push({
                uid: e?.uid,
                name: e?.name,
                file: e,
                size: bytesToMegabytes(e?.size),
                preview: e?.type === "application/pdf" ? pdfPreview : URL.createObjectURL(new Blob([convertToBlobPart(e)], { type: e?.type })),
                status: "uploading",
                progress: 20
            })
            beforeUploadList = [...fileList]
            return false
        }
    };

    const twoColors: ProgressProps['strokeColor'] = {
        '0%': '#108ee9',
        '100%': '#87d068',
    };

    useEffect(() => {
        let tmpCreateData = getSessionStorageData("tmpCreateData")
        if (tmpCreateData.uploadAttachment) {
            setEleImageUpload(tmpCreateData.uploadAttachment)
            tmpCreateData.status = event
            tmpCreateData.paging = 7
            sessionStorage.setItem("tmpCreateData", JSON.stringify(tmpCreateData))
            setIsDisableBtn(false)
        }
    }, [])

    useEffect(() => {
        if (processUpload) {
            let attachmentUploaded = { ...eleImageUpload }
            let objData = {
                uploadAttachment: attachmentUploaded
            }
            //Just mockup for uploading to server
            setTimeout(() => {
                fileList.forEach(item => {
                    if (item.status === "uploading") {
                        item.progress = 70;
                    }
                });
                objData.uploadAttachment[eventUpload] = fileList
                setEleImageUpload({ ...eleImageUpload, [eventUpload]: fileList })
                setTimeout(() => {
                    fileList.forEach(item => {
                        if (item.status === "uploading") {
                            item.progress = 100
                            item.status = "success"
                        }
                    });
                    let tmpCreateData = getSessionStorageData("tmpCreateData")
                    tmpCreateData = Object.assign(tmpCreateData, objData)
                    tmpCreateData.status = event
                    tmpCreateData.paging = 7
                    setSessionStorageData("tmpCreateData", tmpCreateData)
                    setProcessUpload(false)
                }, 1000)
                setIsDisableBtn(false)
            }, 50)
        }
    }, [processUpload])


    function handleSelectUpload(e: MouseEvent<HTMLAnchorElement>) {
        const { id } = e.currentTarget
        let arrTarget: Array<objDetailUploaded> = []
        setEventUploadCss({ [id]: "b-is-info" })
        setEventUpload(id)
        if (eleImageUpload?.[id]) {
            arrTarget = eleImageUpload?.[id]
        }
        setFileList(arrTarget)
    }

    function removeAttachment(target: string) {
        let newUploadList = fileList.filter(item => item.uid !== target);
        let attachmentUploaded = { ...eleImageUpload, [eventUpload]: newUploadList }
        let objData = {
            uploadAttachment: attachmentUploaded
        }
        let tmpCreateData = getSessionStorageData("tmpCreateData")
        tmpCreateData = Object.assign(tmpCreateData, objData)
        setSessionStorageData("tmpCreateData", tmpCreateData)
        setFileList(newUploadList)
        setEleImageUpload(attachmentUploaded)
    }

    return (
        <div className="box" style={{ marginBlockStart: "45px", borderRadius: "90px" }} >
            <div className="columns is-moblie">
                <div className="column is-5" style={{ marginBlockStart: "-10px", marginInlineStart: "70px" }}>
                    <br />
                    <h1 className="content-header font-bold content-left">{t("ATTACHMENT.HEADER")}</h1>
                </div>
            </div>
            <div className="columns is-moblie">
                <div className="column is-5">
                    <div className="columns">
                        <div className="column is-9" style={{ marginInlineStart: "70px" }}>
                            <h4 className="content-left font-bold">
                                {t("ATTACHMENT.SOURCE_CO2E")}
                            </h4>
                        </div>
                        <div className="column is-3">
                            <h4 className="content-left font-bold" style={{ marginLeft: "-15px" }}>{t("ATTACHMENT.FILE_AMOUNT")}</h4>
                        </div>
                    </div>
                    {eventType?.map((item) => {
                        return (
                            <a className={"box b-attachment " + eventUploadCss?.[item.id]} id={item.id} onClick={handleSelectUpload}>
                                <div className="columns is-moblie" >
                                    <div className="column is-9" style={{ marginInlineStart: "30px", marginTop: "-7px" }}>
                                        <h4 className="content-left">{t(item.name)}</h4>
                                    </div>
                                    <div className="column is-3" style={{ marginInlineStart: "20px", marginTop: "-7px" }}>
                                        <p className="content-left">{eleImageUpload?.[item.id]?.length ? eleImageUpload?.[item.id]?.length : 0}</p>
                                    </div>
                                </div>
                            </a>
                        );
                    })}
                </div>
                <div style={{ marginInlineStart: "50px", borderLeft: "2px solid #A6A6A6" }}></div>
                <div className="column is-6" style={{ marginInlineStart: "30px", paddingRight: "55px", marginTop: "-50px" }}>
                    {eventUpload ?
                        <div className="box">
                            <div className="columns">
                                <div className="column is-1">
                                    <div
                                        className="box"
                                        style={{
                                            backgroundColor: "#E8E5E4",
                                            height: "40px",
                                            width: "13px",
                                            paddingTop: "10px",
                                            justifyContent: "center"
                                        }}
                                    >
                                        <i className={"fas " + eventTypeIcon?.[eventUpload]} style={{ fontSize: 17, marginLeft: "-8px" }}></i>
                                    </div>
                                </div>
                                <div className="column is-8" style={{ marginInlineStart: "20px", marginTop: "7px" }}>
                                    <p className="content-left font-bold">
                                        {t(CarbonCategory?.[eventUpload])}
                                    </p>
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column">
                                    <Dragger {...props}
                                        accept="image/png, image/jpg, image/jpeg, application/pdf"
                                        showUploadList={false}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <CloudUploadOutlined />
                                        </p>
                                        <p className="ant-upload-text">{t("ATTACHMENT.UPLOAD_CONTENT")}</p>
                                        <button className="button" type="button">{t("BUTTONS.UPLOAD")}</button>
                                    </Dragger>
                                </div>
                            </div>
                            <p className="content-left" style={{ marginTop: "-15px" }}>{t("ATTACHMENT.UPLOAD_TITLE")}<span className="has-text-danger">*</span></p><br />
                            <p className="content-left">{t("ATTACHMENT.TITLE")}</p><br />

                            <div className={fileList.length! > 3 ? "detail-scroll-attachment" : "detail-attachment"}>
                                {fileList.map((item: objDetailUploaded) => {
                                    return (
                                        <div className={"box b-list-attachment " + (item.status === "failed" ? "attachment-error" : "")}>
                                            <div className="columns">
                                                <div style={{ position: "relative", width: "50px", height: "40px", margin: "auto" }} >
                                                    <img className="upload-list-item-thumbnail" alt="not found" src={item?.preview} />
                                                </div>
                                                <div className="column is-9" >
                                                    <p className="upload-list-item-name content-left" style={{ marginTop: "-10px" }}>{item.name}</p>
                                                    <p className="upload-list-item-name content-left" style={{ marginBottom: "-5px", fontSize: "14px" }}>{item.size}</p>
                                                </div>
                                                <div className="column is-1" ><DeleteIcon className="upload-list-item-actions " onClick={() => removeAttachment(item.uid)} /></div>
                                            </div>
                                            {item.status === "uploading" ?
                                                <div className="columns" style={{ marginTop: "-20px" }} >
                                                    <Progress percent={item.progress} strokeColor={twoColors} size="small" />
                                                </div> : ""
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                        </div> : ""
                    }
                </div>
            </div>
            <div className="column is-2 is-offset-9">
                <Link to="/register/post/7"><button className="button is-fullwidth App" style={{ backgroundColor: "#C8F026", fontSize: "18px", height: "50px", marginInlineStart: "45px" }} disabled={isDisableBtn}>{t("BUTTONS.NEXT")}</button></Link>
            </div>
        </div>

    )

}