import React, { useEffect, useState, MouseEvent, ChangeEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { formatterNumber, getSessionStorageData } from '../../constants/screen/util';
import ThaiQR from '../../constants/img/icon-thaiqr.png';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useTranslation } from 'react-i18next';
import PolicyRefunds from '../Setting/policyRefunds';
import './payment.css';
import '../../App.css';

declare global {
  interface Window {
    ChillPay: any; // Replace `any` with the correct type if available
  }
}

const useScript = (src: string) => {
  useEffect(() => {
    // Check if the script is already present
    if (document.querySelector(`script[src="${src}"]`)) {
      console.log('ChillPay script already loaded.');
      return;
    }

    // Function to load the script
    const loadScript = (url: string) => {
      return new Promise<void>((resolve, reject) => {
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        script.onload = () => {
          console.log('ChillPay script loaded.');
          resolve();
        };
        script.onerror = () => {
          console.error('Failed to load the ChillPay script.');
          reject();
        };
        document.body.appendChild(script);
      });
    };

    // Load the script
    loadScript(src).catch(console.error);

    // Cleanup script on component unmount
    return () => {
      const script = document.querySelector(`script[src="${src}"]`);
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [src]);
};

export default function Payment() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id, event } = useParams();
  const [selectedValue, setSelectedValue] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [summaryTotal, setSummaryTotal] = useState<any>({});
  const [openConfirmPaid, setOpenConfirmPaid] = useState<boolean>(false);
  const [openRefundPolicy, setOpenRefundPolicy] = useState<boolean>(false);
  const [isDisableConfirm, setIsDisableConfirm] = useState<boolean>(true);

  useEffect(() => {
    // merchantId: "M031315",
    const existingScript = document.querySelector(
      "script[src='https://sandbox-cdnv3.chillpay.co/js/widgets.js?v=1.00']"
    );
    if (existingScript) {
      return;
    } else {
      const script = document.querySelector(
        "script[src='https://sandbox-cdnv3.chillpay.co/js/widgets.js?v=1.00']"
      );
      if (script) {
        document.body.removeChild(script);
      }
    }
    const script = document.createElement('script');
    script.src = 'https://sandbox-cdnv3.chillpay.co/js/widgets.js?v=1.00'; // Replace with the actual widget URL
    script.async = true;
    script.onerror = () => {
      console.log('Failed to load ChillPay script');
    };
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    let tmpCreateData = getSessionStorageData('tmpCreateData');
    if (!summaryTotal?.total) {
      if (tmpCreateData.summaryCo2eq) {
        setSummaryTotal(tmpCreateData.summaryCo2eq);
      }
    }
    if (selectedValue === '') {
      setSelectedValue(tmpCreateData?.paidFor);
    }
  });

  function handleClickPaid(e: MouseEvent<HTMLElement>) {
    let tmpCreateDataStr = sessionStorage.getItem('tmpCreateData');
    let tmpCreateData = tmpCreateDataStr ? JSON.parse(tmpCreateDataStr!) : {};
    tmpCreateData.paidStatus = 'success';
    tmpCreateData.paidBy = paymentMethod;
    tmpCreateData.paging = 9;
    sessionStorage.setItem('tmpCreateData', JSON.stringify(tmpCreateData));
    navigate('/register/post/9');
  }
  function handleClickUnPaid(e: MouseEvent<HTMLElement>) {
    let tmpCreateDataStr = sessionStorage.getItem('tmpCreateData');
    let tmpCreateData = tmpCreateDataStr ? JSON.parse(tmpCreateDataStr!) : {};
    tmpCreateData.paidStatus = 'pending';
    tmpCreateData.paidBy = paymentMethod;
    tmpCreateData.paging = 9;
    sessionStorage.setItem('tmpCreateData', JSON.stringify(tmpCreateData));
    navigate('/register/post/9');
  }

  function handleCloseConfirmPaid() {
    setOpenConfirmPaid(false);
  }

  function handleOpenConfirmPaid() {
    setOpenConfirmPaid(true);
  }

  function handleCloseRefundPolicy() {
    setOpenRefundPolicy(false);
  }

  function handleOpenRefundPolicy() {
    setOpenRefundPolicy(true);
  }

  function handleClickAccpetConsent(e: ChangeEvent<HTMLInputElement>) {
    let checkedStatus = e.target.checked;
    setIsDisableConfirm(!checkedStatus);
  }

  const summaryPrice = summaryTotal?.total ? Math.ceil(summaryTotal?.total) * 180 : 0;

  return (
    <div className="column is-8 is-offset-2">
      <div
        className="box"
        style={{ marginBlockStart: '10px', borderRadius: '90px', height: '865px' }}
      >
        <div className="columns is-moblie">
          <div className="column is-10 is-offset-1" style={{ marginBlockStart: '20px' }}>
            <Box
              display="block"
              alignItems="center"
              sx={{
                border: '3px solid grey',
                borderColor: '#C8F026',
                borderRadius: '20px',
              }}
            >
              <div className="column">
                <h1 className="content-sub-header">{t('PAID_PAGE.TITLE')}</h1>
                <h1
                  className="content-header"
                  style={{ fontSize: '42px', fontWeight: '700', color: 'black', marginTop: '-3px' }}
                >
                  {Math.ceil(summaryTotal.total)}
                </h1>
                <h1 className="content-header-s" style={{ color: '#26B3F0' }}>
                  {selectedValue}
                </h1>
              </div>
            </Box>
            <div>
              <form
                id="payment-form"
                action="https://sandbox-cdnv3.chillpay.co/Payment/"
                method="post"
                className="form-horizontal"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: `
                  <modernpay:widget id="modernpay-widget-container" 
              data-merchantid="M035729" 
              data-amount=${summaryPrice * 100} 
              data-orderno="1" 
              data-eventID="1"
              data-customerID="11" 
              data-mobileno="0889999999" 
              data-clientip="182.232.181.139" data-routeno="1" data-currency="764" 
              data-description="Test ZeroCarbon Payment" data-apikey="STjFzTFvxJ0hKeGKhPUXvcSK6biSBdGxlq3UEmNFc6bcfiUTe4o2IMtLieMsxg3b">
              </modernpay:widget>`,
                  }}

                  /* 
                  M031315
                  P1K7HydBem0qKAteQI8oVGz6qnl7KaNdqHPUx40xwdbUUb04st1smwfpgC9015qD */
                />
                {/* <button
                              type="submit"
                              id="btnSubmit"
                              value="Submit"
                              className="btn"
                            >
                              Payment
                            </button> */}
              </form>
            </div>
            <br />
            <div className="columns">
              <div className="column is-4">
                <h1 className="content-sub-header content-left" style={{ fontSize: '20px' }}>
                  {t('PAID_PAGE.AMOUNT_TOTAL')}
                </h1>
              </div>
              <div className="column is-offset-5" style={{ marginTop: '-40px' }}>
                <h1
                  className="content-header content-right"
                  style={{ fontWeight: '700', color: 'black', marginLeft: '24px' }}
                >
                  {'฿' + formatterNumber.format(Math.ceil(summaryTotal.total) * 180)}
                </h1>
                <h1 className="content-sub-header content-right">{'฿180.00/tCO2eq'}</h1>
              </div>
            </div>
            <div className="columns">
              <div className="column is-6 is-offset-6" style={{ alignItems: 'right' }}>
                <button
                  className="button is-large is-fullwidth is-light App"
                  style={{ fontSize: '18px', height: '70px' }}
                  onClick={handleClickUnPaid}
                >
                  {t('PAID_PAGE.BUTTONS.PENDING')}
                </button>
              </div>
              {/* <div className="column" style={{ alignItems: 'right' }}>
                <button
                  className="button is-large is-fullwidth App"
                  style={{
                    backgroundColor: '#C8F026',
                    fontSize: '18px',
                    height: '70px',
                  }}
                  onClick={handleOpenConfirmPaid}
                >
                  {t('PAID_PAGE.BUTTONS.PAID')}
                </button>
              </div> */}
            </div>
            <br />
          </div>
        </div>
        <Dialog
          open={openConfirmPaid}
          onClose={handleCloseConfirmPaid}
          fullWidth
          maxWidth="xs"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          PaperProps={{
            style: {
              borderRadius: '15px', // Adjust the value as needed
            },
          }}
        >
          <DialogContent dividers={false}>
            <div className="columns">
              <div className="column is-10 is-offset-1 ">
                <h3 className="content-sub-header font-bold content-center">
                  {t('PAID_PAGE.CONFIRM_DIAG.TITLE')}
                </h3>
                <br />
                <p className="content-left font-payment">{t('PAID_PAGE.CONFIRM_DIAG.CONTENT')}</p>
              </div>
            </div>
            <div className="columns">
              <div className="column is-9 is-offset-2">
                <label className="checkbox">
                  <input
                    className="checkbox-consent"
                    type="checkbox"
                    onChange={handleClickAccpetConsent}
                  />
                  <p className="content-left font-payment-sm">
                    {t('PAID_PAGE.CONFIRM_DIAG.CONSENT')}
                    <a style={{ color: '#0485db' }} onClick={handleOpenRefundPolicy}>
                      {t('PAID_PAGE.CONFIRM_DIAG.BUTTON')}
                    </a>
                  </p>
                </label>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="column">
              <div className="buttons" style={{ justifyContent: 'center' }}>
                <button
                  className="button is-info is-fullwidth App"
                  type="button"
                  onClick={handleClickPaid}
                  disabled={isDisableConfirm}
                >
                  {t('BUTTONS.COMFIRM')}
                </button>
                <button
                  className="button is-light is-fullwidth App"
                  type="submit"
                  onClick={handleCloseConfirmPaid}
                >
                  {t('BUTTONS.CANCEL')}
                </button>
              </div>
            </div>
          </DialogActions>
        </Dialog>
        <PolicyRefunds open={openRefundPolicy} handleClose={handleCloseRefundPolicy} />
      </div>
    </div>
  );
}
