import { useQuery } from '@tanstack/react-query';
import api from '../axios';

export const useSubCategories = () => {
  return useQuery({
    queryKey: ['subCategories'],
    queryFn: async () => {
      try {
        const { data } = await api.get(`/sub-categories`);
        return data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  });
};
