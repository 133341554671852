import React, { useState, RefCallback, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import PasswordChecklist from "react-password-checklist"
import { useForm, SubmitHandler } from 'react-hook-form';

interface propsChangePassword {
    handleValidPasswordProps: RefCallback<any>;
}
interface propsChangePasswordObj {
    isValid: boolean;
    password: string;
}
interface changePasswordObj {
    confirmPassword: string;
    password: string;
}

export default function ChangePassword(props: propsChangePassword) {
    const { t } = useTranslation();
    const { handleValidPasswordProps } = props
    const [passwordChange, setPasswordChange] = useState<string>("")
    const [passwordChangeConfirm, setPasswordChangeConfirm] = useState<string>("")
    const { register, handleSubmit, formState: { errors } } = useForm<changePasswordObj>();
    console.log(passwordChange)

    function handleValidPassword(isValid: boolean) {
        let objCallback: propsChangePasswordObj = {
            password: passwordChange,
            isValid: isValid
        }
        handleValidPasswordProps(objCallback)
    }

    const submitFunction: SubmitHandler<changePasswordObj> = e => {
    }

    function showPassword(e: MouseEvent) {
        let obj = document.getElementById('setPasswordChange') as HTMLInputElement;
        obj.type === 'text' ? obj.type = 'password' : obj.type = 'text'

        const togglePassword = document.getElementById("togglePasswordChange") as HTMLElement;
        if (togglePassword.classList.contains('fa-eye')) {
            togglePassword.classList.remove('fa-eye');
            togglePassword.classList.add('fa-eye-slash');
        } else {
            togglePassword.classList.remove('fa-eye-slash');
            togglePassword.classList.add('fa-eye');
        }
    }

    function showPasswordConfirm(e: MouseEvent) {
        let obj = document.getElementById('setConfirmPassword') as HTMLInputElement;
        obj.type === 'text' ? obj.type = 'password' : obj.type = 'text'

        const togglePassword = document.getElementById("togglePasswordConfirm") as HTMLElement;
        if (togglePassword.classList.contains('fa-eye')) {
            togglePassword.classList.remove('fa-eye');
            togglePassword.classList.add('fa-eye-slash');
        } else {
            togglePassword.classList.remove('fa-eye-slash');
            togglePassword.classList.add('fa-eye');
        }
    }


    return (
        <form onSubmit={handleSubmit(submitFunction)} action="#">
            <div className="columns">
                <div className="column">
                    <div className="field">
                        <p className="control is-expanded">
                            <p className="content-left">{t("SETTING_PAGE.CHANGE_PASSWORD_DIAG.PASSWORD")}<span className="has-text-danger">*</span></p>
                            <input className="input" type="password" id="setPasswordChange" placeholder={t("SETTING_PAGE.CHANGE_PASSWORD_DIAG.PASSWORD")}
                                style={{ borderRadius: "10px", borderColor: errors.password ? "red" : "", background: errors.password ? "" : "" }}
                                {...register("password", {
                                    required: t("ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG") + t("LOGIN_PAGE.PASSWORD") + t("ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG"),
                                    onChange: (e) => { setPasswordChange(e.target.value) }
                                })} />
                            <i className="fa-solid fa-eye-slash" id="togglePasswordChange" style={{ cursor: "pointer", marginInlineStart: "-30px", marginTop: "13px", position: "absolute" }} onClick={showPassword}></i>
                            {errors?.password ? <p className="content-error">{errors.password.message}</p> : <p className="content-error">&nbsp;</p>}
                        </p>
                    </div>
                </div>
            </div>
            <div className="columns" style={{ marginTop: "-30px" }}>
                <div className="column">
                    <div className="field">
                        <p className="control is-expanded">
                            <p className="content-left">{t("SETTING_PAGE.CHANGE_PASSWORD_DIAG.CONFIRM_PASSWORD")}<span className="has-text-danger">*</span></p>
                            <input className="input" type="password" id="setConfirmPassword" placeholder={t("SETTING_PAGE.CHANGE_PASSWORD_DIAG.CONFIRM_PASSWORD")}
                                style={{ borderRadius: "10px", borderColor: errors.confirmPassword ? "red" : "", background: errors.confirmPassword ? "" : "" }}
                                {...register("confirmPassword", {
                                    required: t("ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG") + t("LOGIN_PAGE.PASSWORD") + t("ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG"),
                                    onChange: (e) => { setPasswordChangeConfirm(e.target.value) }
                                })} />
                            <i className="fa-solid fa-eye-slash" id="togglePasswordConfirm" style={{ cursor: "pointer", marginInlineStart: "-30px", marginTop: "13px", position: "absolute" }} onClick={showPasswordConfirm}></i>
                            {errors?.password ? <p className="content-error">{errors.password.message}</p> : <p className="content-error">&nbsp;</p>}
                        </p>
                    </div>
                </div>
            </div>
            <div className="columns" style={{ marginTop: "-35px" }}>
                <div className="column is-12">
                    <div className="box">
                        <h3 className="content-sub-header content-left">{t("SETTING_PAGE.CHANGE_PASSWORD_DIAG.HEADER")}</h3>
                        <PasswordChecklist
                            rules={["minLength", "number", "specialChar", "lowercase", "capital", "match"]}
                            className="content-left"
                            minLength={8}
                            value={passwordChange}
                            valueAgain={passwordChangeConfirm}
                            onChange={(isValid) => { handleValidPassword(isValid) }}
                            messages={{
                                minLength: t("SETTING_PAGE.CHANGE_PASSWORD_DIAG.MIN_LENGTH"),
                                number: t("SETTING_PAGE.CHANGE_PASSWORD_DIAG.NUMBER"),
                                specialChar: t("SETTING_PAGE.CHANGE_PASSWORD_DIAG.SPECIAL_CHAR"),
                                lowercase: t("SETTING_PAGE.CHANGE_PASSWORD_DIAG.LOWERCASE"),
                                capital: t("SETTING_PAGE.CHANGE_PASSWORD_DIAG.CAPITAL"),
                                match: t("SETTING_PAGE.CHANGE_PASSWORD_DIAG.MATCH")
                            }}
                        />
                    </div>
                </div>
            </div>
        </form>
    );
}
