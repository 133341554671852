import React, { useEffect, useState, MouseEvent } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { green, grey, yellow, red } from "@mui/material/colors";
import CircleIcon from '@mui/icons-material/Circle';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { useNavigate } from "react-router-dom";
import DashboardLogo from "../../constants/img/Character_TGO_08.png"
import { useTranslation } from "react-i18next";
import { getSessionStorageData, setSessionStorageData, isDateLessThanCurrent } from "../../constants/screen/util"
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import SummaryGHG from "./summaryGHG";
import "./dashboard.css"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function Dashboard() {
  interface listProjectVar {
    id: string;
    name: string;
    subName: string;
    date: string;
    action: React.JSX.Element;
    status: React.JSX.Element;
    statusName: string;
    endDateEvent: string;
  }
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [listProject, setListProject] = useState<Array<listProjectVar>>([]);
  const [listProjectTarget, setListProjectTarget] = useState<Array<listProjectVar>>([]);
  const [activeTabId, setActiveId] = useState<string>("allTab");
  const [openPostEvent, setOpenPostEvent] = useState<boolean>(false)
  const [idProjectTarget, setIdProjectTarget] = useState<string>("")
  const [openSummary, setOpenSummary] = useState<boolean>(false)

  const dataPie = [
    { label: "", value: 50, color: "#47BC41" },
    { label: "", value: 10, color: "#B1DEDA" },
    { label: "", value: 10, color: "#E8F8F7" },
    { label: "", value: 15, color: "#FEC236" },
    { label: "", value: 15, color: "#F4DEC9" },
  ];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#CECECE",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function createData(
    id: string,
    name: string,
    subName: string,
    date: string,
    action: React.JSX.Element,
    status: React.JSX.Element,
    statusName: string,
    endDateEvent: string
  ) {
    return { id, name, subName, date, action, status, statusName, endDateEvent };
  }

  const chartData = {
    labels: ['01', '02', '03', '04', '05', '06', '07', '08', '09'],
    datasets: [
      {
        data: [0, 245, 140, 245, 270, 245, 270, 140, 0],
        backgroundColor: '#31B7AC'
      }
    ]
  }
  function handleClickProject(e: MouseEvent<HTMLElement>) {
    if ((e.target as HTMLInputElement).innerHTML === t("DASHBOARD_PAGE.PROJECT_ACTION.SUCCESS")) {
      let projectID = (e.target as HTMLInputElement).id
      let listProjectDataStr = sessionStorage.getItem('listProjectData')
      let listProjectData = JSON.parse(listProjectDataStr!)
      let tmpCreateData = listProjectData?.find((item: any) => item.id.toString() === projectID)
      if (tmpCreateData) {
        sessionStorage.setItem('tmpCreateData', JSON.stringify(tmpCreateData))
        setTimeout(() => {
          setOpenSummary(true)
        }, 50)
      }
    } else {
      setIdProjectTarget((e.target as HTMLInputElement).id)
      setOpenPostEvent(true)
    }
  }
  function handleClickCreateProject(e: MouseEvent<HTMLElement>) {
    sessionStorage.setItem('tmpCreateData', '')
    navigate('register/pre/1')
  }
  function handleClickSetting(e: MouseEvent<HTMLElement>) {
    navigate('setting')
  }

  useEffect(() => {
    let rows = [
      createData(
        "1",
        "งานเปิดตัวแพลตฟอร์มรายงานปริมาณก๊าซเรือนกระจก",
        "ห้องประชุมสถานีกลางบางซื่อ",
        "27/2/2024",
        <button className="button is-rounded is-success is-fullwidth is-small App">
          {t("DASHBOARD_PAGE.PROJECT_ACTION.POST")}
        </button>,
        <CircleIcon
          sx={{
            color: green[400],
            marginTop: "9px",
            marginRight: "10px"
          }}
        />,
        "post",
        "2024-08-10"
      ),
      createData(
        "2",
        "งานนิทัศการนานาชาติสินค้าเพื่อโลก",
        "ห้องประชุมแห่งชาติสิริกิต",
        "27/2/2024",
        <button
          className="button is-rounded is-fullwidth is-small App"
          style={{ backgroundColor: "#FEC236" }}
        >
          {t("DASHBOARD_PAGE.PROJECT_ACTION.PRE")}
        </button>,
        <CircleIcon
          sx={{
            color: yellow[700],
            marginTop: "9px",
            marginRight: "10px"
          }}
        />,
        "pre",
        "2024-08-12"
      ),
      createData(
        "3",
        "งานจัดแสดงผลงานที่เกี่ยวข้องกับเทคโนโลยี Block Chain",
        "ห้องประชุมสถานีกลางบางซื่อ",
        "27/2/2024",
        <button
          className="button is-rounded is-fullwidth is-small App"
          style={{ backgroundColor: "#FEC236" }}
        >
          {t("DASHBOARD_PAGE.PROJECT_ACTION.PRE")}
        </button>,
        <CircleIcon
          sx={{
            color: yellow[700],
            marginTop: "9px",
            marginRight: "10px"
          }}
        />,
        "pre",
        "2027-08-10"
      ),
    ];
    let listProjectDataStr = sessionStorage.getItem("listProjectData");
    let listProjectData = listProjectDataStr
      ? JSON.parse(listProjectDataStr!)
      : [];
    listProjectData.map((item: any) => {
      rows.splice(
        0,
        0,
        createData(
          item.id,
          item.projectName,
          item.projectPlace === "อื่น ๆ" ? item.placeOther : item.projectPlace,
          item.createDate,
          <button
            className={
              "button is-rounded is-fullwidth is-small App " +
              (item.status !== "pre" ? "is-success" : "")
            }
            style={{ backgroundColor: item.status === "pre" ? "#FEC236" : "" }}
            id={item.id}
            onClick={handleClickProject}
          >
            {item.status === "success" ? t("DASHBOARD_PAGE.PROJECT_ACTION.SUCCESS") : (item.status === "pre" ? t("DASHBOARD_PAGE.PROJECT_ACTION.PRE") : ((item.paidStatus === "pending") ? t("DASHBOARD_PAGE.PROJECT_ACTION.POST") : t("DASHBOARD_PAGE.PROJECT_ACTION.PAID")))}
          </button>,
          <CircleIcon
            sx={{
              color: item.status === "pre" ? yellow[700] : green[400],
              marginTop: "9px",
              marginRight: "10px"
            }}
          />,
          item.status === "post" ? ((item.paidStatus === "pending") ? "post" : "paid") : item.status,
          item.endDate
        )
      );
    });
    setListProject(rows);
    setListProjectTarget(rows)
    setActiveId("allTab")
  }, [t]);

  function handleClickTab(e: MouseEvent) {
    const { id } = e.currentTarget
    setActiveId(id)
    if (id === "allTab") {
      setListProjectTarget(listProject)
    } else if (id === "preTab") {
      setListProjectTarget(listProject.filter(item => item.statusName === "pre"))
    } else if (id === "postTab") {
      setListProjectTarget(listProject.filter(item => item.statusName === "post"))
    } else if (id === "paidTab") {
      let filter = listProject.filter(item => item.statusName === "success")
      setListProjectTarget(filter)
    }
  }

  function handleDisplayDelBtn(e: MouseEvent) {
    const { id } = e.currentTarget
    let btnEle = document.getElementById(id + "btn") as HTMLElement
    if (btnEle.style.display === "none") {
      btnEle.style.display = ""
    } else {
      btnEle.style.display = "none"
    }
  }

  function handleClosePostEvent() {
    setIdProjectTarget("")
    setOpenPostEvent(false)
  }

  function handleCloseSummary() {
    setOpenSummary(false)
  }

  function handleDeleteBtn(e: MouseEvent) {
    //coding to remove project here
    const { id } = e.currentTarget
    let newListProject = listProject.filter(item => item.id + "btn" !== id)
    let newListProjectTarget = listProjectTarget.filter(item => item.id + "btn" !== id)
    setListProjectTarget(newListProjectTarget)
    setListProject(newListProject)

    //for sessionStorage
    let ssListProject = getSessionStorageData("listProjectData")
    if (ssListProject.length) {
      let newSSListProject = ssListProject?.filter((item: any) => item.id + "btn" !== id)
      setSessionStorageData("listProjectData", newSSListProject)
    }
  }

  function handleClickPreEvent(e: MouseEvent<HTMLElement>) {
    let projectID = idProjectTarget
    let listProjectDataStr = sessionStorage.getItem('listProjectData')
    let listProjectData = JSON.parse(listProjectDataStr!)
    let tmpCreateData = listProjectData?.find((item: any) => item.id.toString() === projectID)
    if (tmpCreateData) {
      sessionStorage.setItem('tmpCreateData', JSON.stringify(tmpCreateData))
    }
    navigate('register/pre/1')
  }

  function handleClickPostEvent(e: MouseEvent<HTMLElement>) {
    let projectID = idProjectTarget
    let listProjectDataStr = sessionStorage.getItem('listProjectData')
    let listProjectData = JSON.parse(listProjectDataStr!)
    let tmpCreateData = listProjectData?.find((item: any) => item.id.toString() === projectID)
    tmpCreateData.status = 'post'
    tmpCreateData.paging = 0
    if (tmpCreateData) {
      sessionStorage.setItem('tmpCreateData', JSON.stringify(tmpCreateData))
    }
    navigate('register/post/0')
  }

  return (
    <div className="container">
      <br />
      <br />
      <div className="columns is-moblie">
        <h1 className="content-header">{t("DASHBOARD_PAGE.HEADER")}</h1>
      </div>
      <div className="columns is-moblie">
        <h3 className="content-sub-header">{t("DASHBOARD_PAGE.SUBHEADER")}</h3>
      </div>
      <div className="columns is-moblie">
        <div className='column is-4 is-offset-6'>
          <div className="talk-bubble tri-right round btm-left-in">
            <div className="talktext">
              <p>{t("DASHBOARD_PAGE.CONTENT")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="columns is-moblie">
        <div className='column is-3 is-offset-1'>
          <button className='button button-dashboard is-2 is-success is-fullwidth App ' type='button' onClick={handleClickCreateProject}>{t("BUTTONS.CREATE_PROJECT")}</button><br /><br />
        </div>
        <div className='column is-4'>
          <img src={DashboardLogo} style={{ marginLeft: "40px" }} />
        </div>
        <div className='column is-3 is-offset-0'>
          <button className='button button-dashboard is-2 is-fullwidth App' style={{ backgroundColor: '#FEC236' }} type='button' onClick={handleClickSetting}>{t("BUTTONS.SETTING")}</button>
        </div>
      </div>
      <div className="columns is-moblie">
        <div className="column is-3 is-offset-1">
          <h1 className='content-header content-left'>{t("DASHBOARD_PAGE.PROJECT_TABLE.HEADER")}</h1>
          <h3 className='content-sub-header content-left'>{t("DASHBOARD_PAGE.PROJECT_TABLE.SUBHEADER")}</h3>
        </div>
      </div>
      <div className="columns is-moblie">
        <div className="column is-10 is-offset-1 box">
          <div className="tabs is-fullwidth">
            <ul>
              <li className={activeTabId === 'allTab' ? "is-active" : ""}>
                <a id="allTab" onClick={handleClickTab}>
                  <span>{t("DASHBOARD_PAGE.TABS.TAB1")}</span>
                </a>
              </li>
              <li className={activeTabId === 'preTab' ? "is-active" : ""}>
                <a id="preTab" onClick={handleClickTab}>
                  <span>{t("DASHBOARD_PAGE.TABS.TAB2")}</span>
                </a>
              </li>
              <li className={activeTabId === 'postTab' ? "is-active" : ""}>
                <a id="postTab" onClick={handleClickTab}>
                  <span>{t("DASHBOARD_PAGE.TABS.TAB3")}</span>
                </a>
              </li>
              <li className={activeTabId === 'paidTab' ? "is-active" : ""}>
                <a id="paidTab" onClick={handleClickTab}>
                  <span>{t("DASHBOARD_PAGE.TABS.TAB4")}</span>
                </a>
              </li>
            </ul>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table" >
              <TableHead>
                <TableRow>
                  <StyledTableCell className="content-left"></StyledTableCell>
                  <StyledTableCell className="content-left">{t("DASHBOARD_PAGE.PROJECT_TABLE.PROJECT_NAME")}</StyledTableCell>
                  <StyledTableCell className="content">{t("DASHBOARD_PAGE.PROJECT_TABLE.PROJECT_DATE")}</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell align="left" className="content-left">{t("DASHBOARD_PAGE.PROJECT_TABLE.PROJECT_STATUS")}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listProjectTarget.map((row) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell align="left">
                      <a id={row.id} onClick={handleDisplayDelBtn}><RemoveCircleOutlineOutlinedIcon sx={{ color: grey[500], marginTop: "9px", marginRight: "5px" }} /></a>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" className='content-left'>
                      <div>
                        <p className='content-left'>
                          <b>{row.name}</b>
                          {
                            isDateLessThanCurrent(row.endDateEvent) && row.statusName === "pre" ? <CircleIcon
                              sx={{
                                color: red[700],
                                marginTop: "-3px",
                                width: "16px",
                                marginLeft: "10px"
                              }}
                            /> : ""
                          }

                        </p><br />
                        <p className='content-left'>{row.subName}</p>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left" className="content">{row.date}</StyledTableCell>
                    <StyledTableCell>{row.action}</StyledTableCell>
                    <StyledTableCell align="left">{row.status}<button id={row.id + "btn"} className="button is-danger" style={{ display: "none" }} onClick={handleDeleteBtn} type="button">{t('BUTTONS.DELETE')}</button></StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <Dialog
        open={openPostEvent}
        onClose={handleClosePostEvent}
        fullWidth
        maxWidth="xs"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        PaperProps={{
          style: {
            borderRadius: "15px", // Adjust the value as needed
          }
        }}
      >
        <DialogContent dividers={false} >
          <div className="column is-12">
            <h4 className="content-center">{t("DASHBOARD_PAGE.POST_EVENT_DIALOG.CONTENT")}</h4>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="column">
            <div className="buttons" style={{ justifyContent: "center" }}>
              <button className="button App" type="button" onClick={handleClickPreEvent} style={{ backgroundColor: '#FEC236' }}>{t("DASHBOARD_PAGE.POST_EVENT_DIALOG.PRE_EVENT")}</button>
              <button className="button is-success App" type="submit" onClick={handleClickPostEvent} >{t("DASHBOARD_PAGE.POST_EVENT_DIALOG.POST_EVENT")}</button>
            </div>
          </div>
        </DialogActions>
      </Dialog>
      <SummaryGHG open={openSummary} handleClose={handleCloseSummary} />
    </div>
  )
}
