import React, { useState, useEffect, RefCallback, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { getSessionStorageData, setSessionStorageData } from '../../constants/screen/util';
import { useAccept } from '@src/services/queries/useAuthQuery';
import { UserPolicyAcceptance } from '@src/component/layout/admin/common/types';
import { removeCookie } from '@src/utils/remove-cookie';

interface propsPDPA {
  open: boolean;
  refPage: string;
  token?: string | undefined;
  handleClose: RefCallback<any>;
}
interface pdpaDataObj {
  pdpa1: string;
  pdpa2: string;
  pdpa3: string;
}

export default function PDPA(props: propsPDPA) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { open, refPage, handleClose } = props;
  const [isDisableSubmitBtn, setIsDisableSubmitBtn] = useState(true);
  const [selectedValuePDPA1, setSelectedValuePDPA1] = useState('');
  const [selectedValuePDPA2, setSelectedValuePDPA2] = useState('');
  const [selectedValuePDPA3, setSelectedValuePDPA3] = useState('');

  const { mutate, isSuccess } = useAccept();
  useEffect(() => {
    //coding to init pdpa value here
    let ssStorage = sessionStorage.getItem('pdpaSetting');
    let ssStorageSetting = getSessionStorageData('setting');
    if (ssStorage) {
      let pdpaStorage: pdpaDataObj = getSessionStorageData('pdpaSetting');
      setSelectedValuePDPA1(pdpaStorage?.pdpa1 ? pdpaStorage.pdpa1 : 'accept');
      setSelectedValuePDPA2(pdpaStorage?.pdpa2 ? pdpaStorage.pdpa2 : 'accept');
      setSelectedValuePDPA3(pdpaStorage?.pdpa3 ? pdpaStorage.pdpa3 : 'accept');
    } else if (ssStorageSetting?.pdpaSetting) {
      let pdpaStorage: pdpaDataObj = ssStorageSetting.pdpaSetting;
      setSelectedValuePDPA1(pdpaStorage?.pdpa1 ? pdpaStorage.pdpa1 : 'accept');
      setSelectedValuePDPA2(pdpaStorage?.pdpa2 ? pdpaStorage.pdpa2 : 'accept');
      setSelectedValuePDPA3(pdpaStorage?.pdpa3 ? pdpaStorage.pdpa3 : 'accept');
    } else {
      setSelectedValuePDPA1('');
      setSelectedValuePDPA2('');
      setSelectedValuePDPA3('');
    }
  }, [open]);

  useEffect(() => {
    if (
      selectedValuePDPA1 === 'accept' &&
      selectedValuePDPA2 === 'accept' &&
      selectedValuePDPA3 === 'accept'
    ) {
      setIsDisableSubmitBtn(false);
    } else {
      setIsDisableSubmitBtn(true);
    }
  }, [selectedValuePDPA1, selectedValuePDPA2, selectedValuePDPA3]);

  function handleCancel(e: MouseEvent) {
    if (refPage === 'login') {
      //Cancel to login
      navigate('/');
      handleClose('login');
      removeCookie('zero-carbon-auth.session-token');
    } else {
      handleClose('pdpaSetting');
    }
  }

  function handleSubmit(e: MouseEvent<HTMLFormElement>) {
    //coding for submit pdpa here
    e.preventDefault();
    const dataAccept: UserPolicyAcceptance = [
      {
        policyId: 1,
        acception: true,
      },
      {
        policyId: 2,
        acception: true,
      },
      {
        policyId: 3,
        acception: true,
      },
    ];

    mutate({ token: props.token, accept: dataAccept });
    // let obj: pdpaDataObj = {
    //   pdpa1: selectedValuePDPA1,
    //   pdpa2: selectedValuePDPA2,
    //   pdpa3: selectedValuePDPA3,
    // };
    // if (
    //   selectedValuePDPA1 === 'accept' &&
    //   selectedValuePDPA2 === 'accept' &&
    //   selectedValuePDPA3 === 'accept'
    // ) {
    //   if (refPage === 'login') {
    //     let ssStorage = getSessionStorageData('pdpaSetting');
    //     setSessionStorageData('pdpaSetting', Object.assign(ssStorage, obj));
    //     window.location.reload();
    //   } else if (refPage === 'setting') {
    //     let ssStorage = getSessionStorageData('setting');
    //     ssStorage.pdpaSetting = obj;
    //     setSessionStorageData('setting', ssStorage);
    //   }

    // handleClose('pdpaSetting');
    // }
  }

  if (isSuccess) {
    window.location.href = '/dashboard';
    handleClose('pdpaSetting');
  }
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      fullWidth
      maxWidth="md"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      PaperProps={{
        style: {
          borderRadius: '15px', // Adjust the value as needed
        },
      }}
    >
      <form onSubmit={handleSubmit} action="#">
        <DialogTitle id="scroll-dialog-title">
          <div className="columns">
            <div className="column is-6">
              {/* <h3 className="content-sub-header content-left">{t('SETTING_PAGE.PDPA')}</h3> */}
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers={false} style={{ height: '550px', overflow: "hidden" }}>
          {/* <div className="box box-radius"> */}
          <div className="detail-scroll-dialog">
            <div className="columns content-center">
              <div className="column is-10">
                <h3 className="content-sub-header font-bold ">
                  {t('SETTING_PAGE.PDPA_DIAG.HEADER')}
                </h3>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="content">
                  <p className="content">{t('SETTING_PAGE.PDPA_DIAG.CONTENT1')}</p>
                  <p className="content">{t('SETTING_PAGE.PDPA_DIAG.CONTENT2')}</p>
                  <p className="content">{t('SETTING_PAGE.PDPA_DIAG.PDPA1')}</p>
                </div>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => setSelectedValuePDPA1(e.target.value)}
                  defaultValue="accept"
                  className="content-center"
                  value={selectedValuePDPA1}
                  style={{ marginBottom: '25px' }}
                >
                  <FormControlLabel
                    value="accept"
                    control={
                      <Radio sx={{ color: '#49B90D', '&.Mui-checked': { color: '#49B90D' } }} />
                    }
                    label={
                      <p className="content-center">{t('SETTING_PAGE.PDPA_DIAG.ACCEPT_BTN')}</p>
                    }
                  />
                  <FormControlLabel
                    value="reject"
                    control={
                      <Radio sx={{ color: '#49B90D', '&.Mui-checked': { color: '#49B90D' } }} />
                    }
                    label={
                      <p className="content-center">{t('SETTING_PAGE.PDPA_DIAG.REJECT_BTN')}</p>
                    }
                  />
                </RadioGroup>
                <div className="content">
                  <p>{t('SETTING_PAGE.PDPA_DIAG.PDPA2')}</p>
                </div>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => setSelectedValuePDPA2(e.target.value)}
                  defaultValue="accept"
                  className="content-center"
                  value={selectedValuePDPA2}
                  style={{ marginBottom: '25px' }}
                >
                  <FormControlLabel
                    value="accept"
                    control={
                      <Radio sx={{ color: '#49B90D', '&.Mui-checked': { color: '#49B90D' } }} />
                    }
                    label={
                      <p className="content-center">{t('SETTING_PAGE.PDPA_DIAG.ACCEPT_BTN')}</p>
                    }
                  />
                  <FormControlLabel
                    value="reject"
                    control={
                      <Radio sx={{ color: '#49B90D', '&.Mui-checked': { color: '#49B90D' } }} />
                    }
                    label={
                      <p className="content-center">{t('SETTING_PAGE.PDPA_DIAG.REJECT_BTN')}</p>
                    }
                  />
                </RadioGroup>
                <div className="content">
                  <p>{t('SETTING_PAGE.PDPA_DIAG.PDPA3')}</p>
                </div>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => setSelectedValuePDPA3(e.target.value)}
                  defaultValue="accept"
                  className="content-center"
                  value={selectedValuePDPA3}
                  style={{ marginBottom: '25px' }}
                >
                  <FormControlLabel
                    value="accept"
                    control={
                      <Radio sx={{ color: '#49B90D', '&.Mui-checked': { color: '#49B90D' } }} />
                    }
                    label={
                      <p className="content-center">{t('SETTING_PAGE.PDPA_DIAG.ACCEPT_BTN')}</p>
                    }
                  />
                  <FormControlLabel
                    value="reject"
                    control={
                      <Radio sx={{ color: '#49B90D', '&.Mui-checked': { color: '#49B90D' } }} />
                    }
                    label={
                      <p className="content-center">{t('SETTING_PAGE.PDPA_DIAG.REJECT_BTN')}</p>
                    }
                  />
                </RadioGroup>
                <div className="content">
                  <p>{t('SETTING_PAGE.PDPA_DIAG.CONTENT3')}</p>
                  <p>{t('SETTING_PAGE.PDPA_DIAG.CONTENT4')}</p>
                  <p>{t('SETTING_PAGE.PDPA_DIAG.CONTENT5')}</p>
                  <p>{t('SETTING_PAGE.PDPA_DIAG.CONTENT6')}</p>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </DialogContent>
        <DialogActions>
          <div className="column">
            <div className="buttons" style={{ justifyContent: 'center' }}>
              <button
                className="button is-rounded is-danger App"
                type="button"
                onClick={handleCancel}
              >
                {t('BUTTONS.CANCEL')}
              </button>
              <button
                className="button is-rounded is-info App"
                type="submit"
                disabled={isDisableSubmitBtn}
              >
                {t('BUTTONS.SAVE')}
              </button>
            </div>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
}
