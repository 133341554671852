import React, { useEffect, RefCallback, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm, SubmitHandler } from 'react-hook-form';
import { getSessionStorageData, setSessionStorageData } from "../../constants/screen/util"

interface propsUpdateProfile {
    open: boolean;
    handleClose: RefCallback<string>;
}
interface profileDataObj {
    name: string;
    tel: string;
}


export default function UpdateProfile(props: propsUpdateProfile) {
    const { t } = useTranslation();
    const { open, handleClose } = props
    const { register, handleSubmit, trigger, formState: { errors } } = useForm<profileDataObj>();
    useEffect(() => {
        //coding to init profile value here
        let ssStorage = sessionStorage.getItem("accountProfile")
        let ssStorageSetting = getSessionStorageData("setting")
        setTimeout(() => {
            let elementName = document.getElementById("setName") as HTMLInputElement;
            let elementTel = document.getElementById("setTel") as HTMLInputElement;
            if (ssStorage) {
                let accountStorage: profileDataObj = getSessionStorageData("accountProfile")
                if (elementName) {
                    elementName.value = accountStorage.name
                }
                if (elementTel) {
                    elementTel.value = accountStorage.tel
                }
            } else if (ssStorageSetting?.accountProfile) {
                let accountStorage: profileDataObj = ssStorageSetting.accountProfile
                if (elementName) {
                    elementName.value = accountStorage.name
                }
                if (elementTel) {
                    elementTel.value = accountStorage.tel
                }
            }
        }, 50)
    }, [open])

    function handleCancel(e: MouseEvent) {
        handleClose("updateAcct")
    }

    const handleSubmitForm: SubmitHandler<profileDataObj> = e => {
        //coding for submit profile here
        let ssStorage = getSessionStorageData("setting")
        let obj: profileDataObj = {
            name: "",
            tel: ""
        };
        let elementTarget = document.getElementById("setName") as HTMLInputElement;
        if (elementTarget?.value) {
            obj.name = elementTarget.value
        }
        elementTarget = document.getElementById("setTel") as HTMLInputElement;
        if (elementTarget?.value) {
            obj.tel = elementTarget.value
        }
        ssStorage.accountProfile = obj
        setSessionStorageData("setting", ssStorage)
        handleClose("updateAcct")
    }

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            fullWidth
            maxWidth="sm"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            PaperProps={{
                style: {
                    borderRadius: "15px", // Adjust the value as needed
                }
            }}
        >
            <form onSubmit={handleSubmit(handleSubmitForm)} action="#" >
                <DialogTitle id="scroll-dialog-title">
                    <div className="columns">
                        <div className="column is-6">
                            <h3 className="content-sub-header content-left font-bold">{t("SETTING_PAGE.UPDATE_ACCOUNT")}</h3>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent dividers={false} style={{ height: "190px" }}>
                    <div className="columns">
                        <div className="column is-12">
                            <div className="field">
                                <p className="control is-expanded">
                                    <p className="content-left">{t("SETTING_PAGE.UPDATE_ACCOUNT_DIAG.NAME")}<span className="has-text-danger">*</span></p>
                                    <input className="input" type="text" id="setName" placeholder={t("SETTING_PAGE.UPDATE_ACCOUNT_DIAG.NAME")}
                                        style={{ borderRadius: "10px", borderColor: errors.name ? "red" : "", background: errors.name ? "" : "" }}
                                        {...register("name", {
                                            required: t("ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG") + t("SETTING_PAGE.UPDATE_ACCOUNT_DIAG.NAME") + t("ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG2")
                                        })} />
                                    {errors?.name ? <p className="content-error">{errors.name.message}</p> : <p className="content-error">&nbsp;</p>}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="columns" style={{ marginTop: "-30px", marginBottom: "-30px" }}>
                        <div className="column is-12">
                            <div className="field">
                                <p className="control is-expanded">
                                    <p className="content-left">{t("SETTING_PAGE.UPDATE_ACCOUNT_DIAG.PHONE")}<span className="has-text-danger">*</span></p>
                                    <input className="input" type="tel" id="setTel" placeholder={t("SETTING_PAGE.UPDATE_ACCOUNT_DIAG.PHONE")}
                                        style={{ borderRadius: "10px", borderColor: errors.tel ? "red" : "", background: errors.tel ? "" : "" }}
                                        {...register("tel", {
                                            required: t("ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG") + t("SETTING_PAGE.UPDATE_ACCOUNT_DIAG.PHONE") + t("ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG"),
                                            pattern: {
                                                value: /^[0-9]{10}$/,
                                                message: t("ERROR_MSG_FORM.ERROR_PHONE_MSG")
                                            }
                                        })} />
                                    {errors?.tel ? <p className="content-error">{errors.tel.message}</p> : <p className="content-error">&nbsp;</p>}
                                </p>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className="column">
                        <div className="buttons" style={{ justifyContent: "center" }}>
                            <button className="button is-rounded is-danger App" type="button" onClick={handleCancel}>{t("BUTTONS.CANCEL")}</button>
                            <button className="button is-rounded is-info App" type="submit">{t("BUTTONS.SAVE")}</button>
                        </div>
                    </div>
                </DialogActions>
            </form>
        </Dialog>

    );
}
