import React from 'react';
import { Navigate, Routes as ReactRoutes, Route } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import Register from '../pages/Register';
import Login from '../pages/Login';
import Setting from '../pages/Setting';

// export default function Routes() {
//     const token = sessionStorage.getItem("token");
//     const role = sessionStorage.getItem("role");
//     // let userData = UserLogin ? JSON.parse(UserLogin) : null;
//     const location = useLocation();
//     if (!token) {
//         if (location.pathname.includes("admin")) {
//             return <AdminRoutes />;
//         } else {
//             return <PublicRoutes />;
//         }
//     }
//     return role === "admin" ? <AdminRoutes /> : <PublicRoutes />;
// }

export default function Routes() {
  return (
    <ReactRoutes>
      <Route path="/login" element={<Login />} />;
      <Route path="/" element={<Dashboard />} />;
      <Route path="/register/:event/:id" element={<Register />} />;
      <Route path="/setting" element={<Setting />} />;
      <Route path="*" element={<Navigate to="/" replace />} />
    </ReactRoutes>
  );
}
