import React, { FC, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Box,
  Grid,
  IconButton,
} from '@mui/material';

import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { ProjectCreate } from '@src/types';
import { useCreateProject, useUpdateProject } from '@src/services/queries';
import Swal from 'sweetalert2';
import { ProjectFormInput, projectFormSchema } from '@src/validators/projectFormSchema';

interface FormProjectsProps {
  open: boolean;
  setOpenForm: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose?: () => void;
  defaultValue?: ProjectCreate | undefined;
}

export const FormProjects: FC<FormProjectsProps> = ({
  open,
  setOpenForm,
  handleClose,
  defaultValue,
}) => {
  const QueryCreateProject = useCreateProject();
  const QueryUpdateProject = useUpdateProject();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<ProjectFormInput>({
    resolver: zodResolver(projectFormSchema),
  });

  const handleCreateProject = async (projectData: ProjectFormInput) => {
    try {
      await QueryCreateProject.mutateAsync({ ...projectData, price: Number(projectData.price) });
      setOpenForm(false);

      Swal.fire({
        title: 'สร้างโครงการสําเร็จ',
        text: 'เพิ่มโครงการสําเร็จ',
        icon: 'success',
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
        customClass: {
          popup: 'popup-backend',
        },
      }).then(({ isDismissed }) => {
        if (isDismissed) {
          reset({
            nameTh: '',
            nameEn: '',
            price: '',
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateProject = async (projectData: ProjectFormInput) => {
    try {
      const sendData = {
        ...projectData,
        price: Number(projectData.price),
        id: defaultValue?.id,
      };
      await QueryUpdateProject.mutateAsync(sendData);
      setOpenForm(false);
      Swal.fire({
        title: 'แก้ไขโครงการสําเร็จ',
        text: 'แก้ไขโครงการสําเร็จ',
        icon: 'info',
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
        customClass: {
          popup: 'popup-backend',
        },
      }).then(({ isDismissed }) => {
        if (isDismissed) {
          reset({
            nameTh: '',
            nameEn: '',
            price: '',
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = (data: ProjectFormInput) => {
    if (defaultValue) {
      handleUpdateProject(data);
    } else {
      handleCreateProject(data);
    }
  };
  useEffect(() => {
    if (defaultValue) {
      setValue('nameTh', defaultValue?.nameTh);
      setValue('nameEn', defaultValue?.nameEn);
      setValue('price', String(defaultValue?.price));
    }
  }, [defaultValue, setValue]);

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  return (
    <Dialog fullWidth maxWidth={'md'} open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {defaultValue ? `แก้ไขข้อมูล Project` : 'สร้างข้อมูล Project'}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5}>
              <label>
                ชื่อข้อมูล(TH) <span className="text-red-600">*</span>
              </label>
              <TextField
                {...register('nameTh')}
                error={!!errors.nameTh}
                helperText={errors.nameTh?.message}
                fullWidth
                size="small"
                inputProps={{
                  sx: { borderRadius: 1.6, backgroundColor: 'white' },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <label>
                ชื่อข้อมูล(EN) <span className="text-red-600">*</span>
              </label>
              <TextField
                {...register('nameEn')}
                error={!!errors.nameEn}
                helperText={errors.nameEn?.message}
                fullWidth
                size="small"
                inputProps={{
                  sx: { borderRadius: 1.6, backgroundColor: 'white' },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <label>
                ราคา <span className="text-red-600">*</span>
              </label>
              <TextField
                size="small"
                {...register('price')}
                error={!!errors.price}
                helperText={errors.price?.message}
                fullWidth
                inputProps={{
                  sx: { borderRadius: 1.6, backgroundColor: 'white' },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={handleClose}
            color="inherit"
            sx={{ borderRadius: '8px', alignItems: 'center', gap: 0.5 }}
            variant="contained"
          >
            <CloseTwoToneIcon />
            <Box pt={0.4}> ปิดหน้าต่าง</Box>
          </Button>
          <Button
            type="submit"
            variant="contained"
            color={defaultValue ? 'secondary' : 'primary'}
            sx={{ borderRadius: '8px', alignItems: 'center', gap: 0.5 }}
          >
            {defaultValue ? (
              <>
                <EditTwoToneIcon />
                <Box pt={0.4}> ยันยันแก้ไข</Box>
              </>
            ) : (
              <>
                <CheckCircleTwoToneIcon />
                <Box pt={0.4}> ยันยัน</Box>
              </>
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
