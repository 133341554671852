import { useDeleteUser, useUsers } from '@src/services/queries/useUsersQuery';
import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { applyFilter, emptyRows, getComparator } from '../../../components/TableZeroCarbon/utils';
import {
  Box,
  Button,
  Card,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
} from '@mui/material';

import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import Swal from 'sweetalert2';
import { UserCreate, Users } from '@src/types';
import { ButtonAdd } from '@admin/components/Element/ButtonAdd';

import TableToolBar from '../../../components/TableZeroCarbon/TableToolBar';
import Scrollbar from '@src/component/scrollbar';
import TableHeadZero from '../../../components/TableZeroCarbon/TableHead';
import TableLoading from '../../../components/TableZeroCarbon/TableLoading';
import TableRowZeroCarbon from '../../../components/TableZeroCarbon/TableRow';
import TableEmptyRows from '../../../components/TableZeroCarbon/TableEmptyRows';
import TableNoData from '../../../components/TableZeroCarbon/TableNoData';
import { formatDate } from '@src/utils/format-date-th';
import Iconify from '../../../components/iconify';
import { FormUser } from '../form-user';
const UserTable = () => {
  const queryClient = useQueryClient();

  const [openForm, setOpenForm] = useState(false);
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('createdAt');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [authType, setAuthType] = useState<string>('CREDENTIAL');
  const [updateUser, setUpdateUser] = useState<UserCreate | undefined>(undefined);

  const { data, isLoading } = useUsers(authType, page + 1, rowsPerPage);
  const deleteUserMutation = useDeleteUser();
  const handleSort = (event: any, id: any) => {
    const isAsc = orderBy === id && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(id);
  };

  const columns = [
    { id: '', label: 'NO.', align: 'center' },
    { id: 'firstName', label: 'ชื่อ' },
    { id: 'email', label: 'อีเมล', align: 'center' },
    { id: 'tel', label: 'เบอร์โทร' },
    { id: 'role', label: 'บทบาท' },
    { id: 'createdAt', label: 'วันที่สร้าง' },
    { id: '', label: 'เครื่องมือ' },
  ];
  const handleChangePage = (event: unknown, newPage: number) => setPage(newPage);

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilter = (event: any) => {
    setPage(0);
    setFilterName(event);
  };

  const users = data?.data || [];

  const filtered = applyFilter({
    inputData: users,
    comparator: getComparator(order, orderBy),
    filterName,
    keyField: ['firstName', 'lastName', 'email', 'tel'],
  });

  const notFound = !filtered.length && !!filterName;

  const onClickAddUser = () => {
    setOpenForm(true);
  };

  const onEditUser = (user: UserCreate) => {
    setUpdateUser(user);
    setOpenForm(true);
    console.log(user);
  };

  const TableRowBody = ({ index, rows }: { index: number; rows: UserCreate }) => {
    const { id, firstName, lastName, email, tel, role, createdAt } = rows;
    const onDelete = (id: number | undefined) => {
      Swal.fire({
        title: 'ต้องการลบผู้ใช้งานนี้?',
        text: 'คุณต้องการลบผู้ใช้งานนี้หรือไม่?',
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#1AA238',
        cancelButtonColor: '#d33',
        cancelButtonText: 'ยกเลิก',
        confirmButtonText: 'ยืนยัน',
        customClass: {
          popup: 'popup-backend',
        },
      }).then((result: any) => {
        if (result.isConfirmed) {
          deleteUserMutation.mutateAsync(id);
        }
      });
    };

    return (
      <>
        <TableCell
          sx={{
            textAlign: 'center',
          }}
        >
          {index + 1}
        </TableCell>
        <TableCell scope="row" className="text-xs whitespace-nowrap text-left">
          <div className="truncate max-w-72 flex flex-col" title={firstName}>
            {`${firstName} ${lastName}`}
          </div>
        </TableCell>
        <TableCell sx={{ textAlign: 'center' }} className="text-xs">
          {email}
        </TableCell>

        <TableCell className="text-xs">{tel}</TableCell>
        <TableCell className="text-xs">{role}</TableCell>
        <TableCell className="text-xs">{formatDate(createdAt ?? '')}</TableCell>
        <TableCell align="right">
          <IconButton aria-label="edit" size="small" onClick={() => onEditUser(rows)}>
            <EditTwoToneIcon />
          </IconButton>

          <IconButton aria-label="delete" size="small" onClick={() => onDelete(id)}>
            <DeleteTwoToneIcon />
          </IconButton>
        </TableCell>
      </>
    );
  };

  return (
    <>
      <FormUser
        open={openForm}
        defaultValue={updateUser}
        setOpenForm={setOpenForm}
        handleClose={() => setOpenForm(false)}
      />
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
        <Box>
          <Button
            onClick={() => setAuthType('CREDENTIAL')}
            variant="contained"
            color={authType === 'CREDENTIAL' ? 'success' : 'inherit'}
          >
            <Iconify icon="carbon:credentials" sx={{ mr: 0.5 }} />
            CREDENTIAL
          </Button>
          <Button
            onClick={() => setAuthType('GOOGLE')}
            variant="contained"
            color={authType === 'GOOGLE' ? 'success' : 'inherit'}
          >
            <Iconify icon="flat-color-icons:google" sx={{ mr: 0.5 }} />
            GOOGLE
          </Button>
          {/* <Button variant="contained" disabled>
            <Iconify icon="logos:apple" sx={{ mr: 0.5 }} />
            Apple
          </Button> */}
        </Box>
        <TableToolBar
          onSearch={handleFilter}
          ButtonAdd={<ButtonAdd text="เพิ่มผู้ใช้งาน" onClick={onClickAddUser} />}
        />
      </Stack>

      <Scrollbar>
        <Card>
          <TableContainer component={Paper}>
            <Table
              sx={{
                minWidth: {
                  sm: 1700,
                  md: 1600,
                  lg: 1300,
                },
              }}
              size={'small'}
            >
              <colgroup>
                <col width="1%" />
                <col width="25%" />
                <col width="25%" />
                <col width="10%" />
                <col width="7%" />
                <col width="7%" />
                <col width="7%" />
              </colgroup>
              <TableHeadZero
                order={order}
                orderBy={orderBy}
                onRequestSort={handleSort}
                headLabel={columns}
              />

              <TableBody sx={{ fontSize: '0.75rem' }}>
                {isLoading && <TableLoading />}

                {filtered.map((row, index) => (
                  <TableRowZeroCarbon
                    key={index}
                    rows={row}
                    index={index}
                    TableRowBody={<TableRowBody index={index} rows={row} />}
                  />
                ))}

                <TableEmptyRows
                  height={notFound ? 2 : 5}
                  emptyRows={emptyRows(page, rowsPerPage, filtered.length)}
                />

                {notFound && <TableNoData query={filterName} />}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Scrollbar>
      <TablePagination
        count={data?.total || 0}
        onPageChange={handleChangePage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default UserTable;
