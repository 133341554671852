import React, { createContext, ReactNode, useContext } from "react";
import { useNavigate } from "react-router-dom";
interface AuthContextType {
  logout: () => void;
}
interface ProtectedRouteProps {
  children: ReactNode;
}
const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<ProtectedRouteProps> = ({ children }) => {
  const navigate = useNavigate();
  const logout = () => {
    const UserLogin = sessionStorage.getItem("UserLogin");
    let userData = UserLogin ? JSON.parse(UserLogin) : null;
    sessionStorage.removeItem("UserLogin");
    if (userData && userData.role === "admin") {
      navigate("/admin/login");
    } else {
      navigate("/login");
    }
  };

  return (
    <AuthContext.Provider value={{ logout }}>{children}</AuthContext.Provider>
  );
};
