import React, { useEffect, useState, MouseEvent, RefCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { eventType } from "../../constants/screen/eventType";
import Logo from "../../constants/img/carbon_logo.jpeg";
import Box from "@mui/material/Box";
import { formatterNumber, formatterDate } from "../../constants/screen/util";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";

interface propsSummary {
    open: boolean;
    handleClose: RefCallback<any>;
}

export default function SummaryGHG(props: propsSummary) {
    const { open, handleClose } = props
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { event, id } = useParams();
    const [eventData, setEventData] = useState<any>({})
    const [summaryTotal, setSummaryTotal] = useState<any>({});

    useEffect(() => {
        let tmpCreateProjectStr = sessionStorage.getItem("tmpCreateData");
        let tmpCreateProject = tmpCreateProjectStr
            ? JSON.parse(tmpCreateProjectStr!)
            : "";
        let detailEventSession = tmpCreateProject.eventDetail
            ? tmpCreateProject.eventDetail
            : {};
        let detailEventSessionID = Object.keys(detailEventSession);
        let summaryCo2eq: any = {};
        let totalCo2eq = 0;
        eventType.map((eventTypeItem) => {
            detailEventSessionID.map((detailItem) => {
                if (
                    detailEventSession[detailItem]?.eventCategory === eventTypeItem.id
                ) {
                    summaryCo2eq[eventTypeItem.id]
                        ? (summaryCo2eq[eventTypeItem.id] +=
                            detailEventSession[detailItem]?.totalCo2eq / 1000)
                        : (summaryCo2eq[eventTypeItem.id] =
                            detailEventSession[detailItem]?.totalCo2eq / 1000);
                    totalCo2eq += detailEventSession[detailItem]?.totalCo2eq;
                }
            });
        });
        summaryCo2eq.total = totalCo2eq / 1000;
        summaryCo2eq.totalPperson = totalCo2eq / parseFloat(tmpCreateProject.attendees) / 1000;
        setSummaryTotal(summaryCo2eq);
        setEventData(tmpCreateProject)
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="lg"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            PaperProps={{
                style: {
                    borderRadius: "15px", // Adjust the value as needed
                }
            }}
        >
            <DialogTitle>
                <div className="columns" style={{ marginBlockStart: "15px", marginInlineStart: "15px" }}>
                    <div className="column">
                        <h3 className="content-sub-header content-left font-bold">{eventData.projectName}</h3>
                        <h3 className="content-sub-header content-left">{eventData.projectPlace}</h3>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div
                    className="box"
                    style={{ marginBlockStart: "10px", marginInlineStart: "15px", marginInlineEnd: "15px", borderRadius: "90px" }}
                >
                    <div className="columns is-moblie">
                        <div className="column is-offset-1">
                            <h1 className="content-header content-left">{t("DASHBOARD_PAGE.SUMMARY_DIALOG.HEADER")}</h1>
                        </div>
                    </div>
                    <div className="columns is-moblie">
                        <div className="column is-5 is-offset-1">
                            <div className="columns">
                                <div className="column is-7">
                                    <h1 className="content-sub-header content-left font-bold">
                                        {t("DASHBOARD_PAGE.SUMMARY_DIALOG.SOURCE_CO2E")}
                                    </h1>
                                    <br />
                                </div>
                                <div className="column is-5">
                                    <h1 className="content-sub-header content-left font-bold">
                                        {t("DASHBOARD_PAGE.SUMMARY_DIALOG.SOURCE_CO2E_AMT")}
                                    </h1>
                                    <br />
                                </div>
                            </div>
                            {eventType.map((item, index) => {
                                return (
                                    <div className="columns" key={index}>
                                        <div className="column is-8" style={{ marginTop: "-30px" }}>
                                            <h1 className="content-left">{t(item.name)}</h1>
                                            <br />
                                        </div>
                                        <div className="column is-4" style={{ marginTop: "-30px" }}>
                                            <h1 className="content-left">
                                                <b>
                                                    {summaryTotal[item.id]
                                                        ? formatterNumber.format(summaryTotal[item.id])
                                                        : "0.00"}
                                                </b>
                                                &nbsp;&nbsp;
                                                {summaryTotal[item.id]
                                                    ? formatterNumber.format(
                                                        (summaryTotal[item.id] / summaryTotal.total) * 100
                                                    ) + "%"
                                                    : "0.00%"}
                                            </h1>
                                            <br />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="column is-4 is-offset-1">
                            <Box
                                display="block"
                                alignItems="center"
                                sx={{
                                    border: "5px solid grey",
                                    borderColor: "#C8F026",
                                    borderRadius: "8px",
                                    marginTop: "-15px"
                                }}
                            >
                                <div className="column">
                                    <h1 className="content-header-s content-center">{t("DASHBOARD_PAGE.SUMMARY_DIALOG.TOTAL_CO2E")}</h1>
                                </div>
                                <div
                                    className="column"
                                    style={{ marginTop: "-25px" }}
                                >
                                    <h1
                                        className="content-header font-bold content-center"
                                        style={{ color: "#26B3F0" }}
                                    >
                                        {formatterNumber.format(summaryTotal.total) + t("DASHBOARD_PAGE.SUMMARY_DIALOG.UNIT")}
                                    </h1>
                                </div>
                                <div
                                    className="column"
                                    style={{ marginTop: "-25px" }}
                                >
                                    <h1 className="content-header-s content-center">{t("DASHBOARD_PAGE.SUMMARY_DIALOG.AVG_PER_PERSON")}</h1>
                                </div>
                                <div
                                    className="column"
                                    style={{ marginTop: "-25px" }}
                                >
                                    <h1
                                        className="content-header font-bold content-center"
                                        style={{ color: "#26B3F0" }}
                                    >
                                        {formatterNumber.format(summaryTotal.totalPperson) + t("DASHBOARD_PAGE.SUMMARY_DIALOG.UNIT")}
                                    </h1>
                                </div>
                            </Box>
                            <br />
                            <a
                                className="button is-large is-fullwidth App"
                                style={{
                                    backgroundColor: "#54B154",
                                    fontSize: "16px",
                                    color: "white",
                                    height: "45px",
                                    marginBottom: "10px"
                                }}
                                href={Logo}
                                download
                            >
                                {t("DASHBOARD_PAGE.SUMMARY_DIALOG.BUTTON1")}
                            </a>
                            <a
                                className="button is-large is-fullwidth App"
                                style={{
                                    backgroundColor: "#54B154",
                                    fontSize: "16px",
                                    color: "white",
                                    height: "45px",
                                    marginBottom: "10px"
                                }}
                                href={Logo}
                                download
                            >
                                {t("DASHBOARD_PAGE.SUMMARY_DIALOG.BUTTON2")}
                            </a>
                            <button
                                className="button is-large is-fullwidth App"
                                style={{
                                    backgroundColor: "#6F6F6F",
                                    fontSize: "16px",
                                    color: "white",
                                    height: "45px",
                                    marginBottom: "10px"
                                }}
                                type="button"
                                onClick={handleClose}
                            >
                                {t("BUTTONS.CLOSE")}
                            </button>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}
