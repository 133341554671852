import React, { useEffect } from 'react';
import Login from '../../pages/Login';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import App from '../../App';
import Cookies from 'js-cookie';
import { decodeJwt } from '@src/utils/decode-jwt';
import { config } from '@src/config';

export default function Auth() {
  let userID = sessionStorage.getItem('UserLogin');
  const isTokenExpired = () => {
    const token = Cookies.get(config.keyNameCookie);
    if (!token) return true; // If no token, consider it expired
    try {
      const decodedToken = decodeJwt(token); // Decode the token
      const expirationTime = decodedToken.exp * 1000;
      if (!expirationTime) return true;

      return Date.now() > expirationTime;
    } catch (error) {
      console.error('Error decoding token:', error);
      return true;
    }
  };
  useEffect(() => {
    const validateSession = () => {
      const token = Cookies.get(config.keyNameCookie); // Replace 'sessionToken' with your cookie name
      if (!token) {
        console.log('login');
        // redirect to login page
      }
    };

    validateSession();
  }, []);

  console.log(isTokenExpired());
  if (isTokenExpired()) {
    return (
      <BrowserRouter>
        <Login />
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <App />
      </BrowserRouter>
    );
  }
}
