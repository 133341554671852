import React, { useState, MouseEvent, useEffect } from 'react';
import { Radio } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ErrorNotify } from '../../constants/screen/toast';
import { useTranslation } from 'react-i18next';
import { getAction } from '../../redux/action';
import { getSessionStorageData, setSessionStorageData } from '../../constants/screen/util';

export default function RegisterMice() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState('meeting');

  useEffect(() => {
    let tmpCreateData = getSessionStorageData('tmpCreateData');
    if (tmpCreateData?.projectType) {
      setSelectedValue(tmpCreateData?.projectType);
    }
  }, []);

  const handleChange = (event: MouseEvent<HTMLElement>, nextView: string) => {
    if (nextView !== undefined) setSelectedValue(nextView);
  };

  const controlProps = (item: string) => ({
    checked: selectedValue === item,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });

  function handleClickNext(e: MouseEvent<HTMLElement>) {
    if (!selectedValue) {
      ErrorNotify('กรุณาเลือกประเภทโครงการ');
    } else {
      let objData = {
        projectType: selectedValue,
        status: 'pre',
        paging: 2,
      };
      let tmpCreateData = getSessionStorageData('tmpCreateData');
      setSessionStorageData('tmpCreateData', Object.assign(tmpCreateData, objData));
      navigate('/register/pre/2');
    }
  }

  return (
    <div>
      <div className="columns is-moblie">
        <div className="column">
          <br />
          <br />
          <h1 className="content-header content-left">{t('MICE_PAGE.HEADER')}</h1>
        </div>
      </div>
      <div className="columns is-moblie">
        <div className="column">
          <ToggleButtonGroup
            color="success"
            value={selectedValue}
            exclusive
            onChange={handleChange}
          >
            <ToggleButton
              value="meeting"
              aria-label="list"
              style={{
                borderColor: '#E2E2E2',
                width: '350px',
                borderRadius: '30px',
                display: 'block',
                textTransform: 'none',
              }}
            >
              <h1 className="content-header" style={{ fontSize: 80 }}>
                M
              </h1>
              <br />
              <h1 className="content-header-s">{t('MICE_PAGE.M_EN')}</h1>
              <h1 className="content-header-s" style={{ fontSize: '22px' }}>
                {t('MICE_PAGE.M_TH')}
              </h1>
              <br />
              <Radio
                {...controlProps('meeting')}
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 40,
                    color: '#49B90D',
                  },
                }}
              />
            </ToggleButton>
            <ToggleButton
              value="incentives"
              aria-label="list"
              style={{
                borderColor: '#E2E2E2',
                width: '350px',
                borderRadius: '30px',
                display: 'block',
                marginInlineStart: '10px',
                textTransform: 'none',
              }}
            >
              <h1 className="content-header" style={{ fontSize: 80 }}>
                I
              </h1>
              <br />
              <h1 className="content-header-s">{t('MICE_PAGE.I_EN')}</h1>
              <h1 className="content-header-s" style={{ fontSize: '22px' }}>
                {t('MICE_PAGE.I_TH')}
              </h1>
              <br />
              <Radio
                {...controlProps('incentives')}
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 40,
                    color: '#49B90D',
                  },
                }}
              />
            </ToggleButton>
            <ToggleButton
              value="conventions"
              aria-label="list"
              style={{
                borderColor: '#E2E2E2',
                width: '350px',
                borderRadius: '30px',
                display: 'block',
                marginInlineStart: '10px',
                textTransform: 'none',
              }}
            >
              <h1 className="content-header" style={{ fontSize: 80 }}>
                C
              </h1>
              <br />
              <h1 className="content-header-s">{t('MICE_PAGE.C_EN')}</h1>
              <h1 className="content-header-s" style={{ fontSize: '22px' }}>
                {t('MICE_PAGE.C_TH')}
              </h1>
              <br />
              <Radio
                {...controlProps('conventions')}
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 40,
                    color: '#49B90D',
                  },
                }}
              />
            </ToggleButton>
            <ToggleButton
              value="exhibition"
              aria-label="list"
              style={{
                borderColor: '#E2E2E2',
                width: '350px',
                borderRadius: '30px',
                display: 'block',
                marginInlineStart: '10px',
                textTransform: 'none',
              }}
            >
              <h1 className="content-header" style={{ fontSize: 80 }}>
                E
              </h1>
              <br />
              <h1 className="content-header-s">{t('MICE_PAGE.E_EN')}</h1>
              <h1 className="content-sub-header" style={{ fontSize: '22px' }}>
                {t('MICE_PAGE.E_TH')}
              </h1>
              <br />
              <Radio
                {...controlProps('exhibition')}
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 40,
                    color: '#49B90D',
                  },
                }}
              />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <div className="columns">
        <div className="column is-4 is-offset-4" style={{ alignItems: 'right' }}>
          <br />
          <br />
          <button
            className="button is-rounded is-large is-fullwidth is-success App"
            onClick={handleClickNext}
          >
            {t('BUTTONS.NEXT')}
          </button>
        </div>
      </div>
    </div>
  );
}
